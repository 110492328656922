













import Vue from 'vue'
// @ts-ignore
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import { mapGetters } from 'vuex'
import { ascSort } from '@utils/common-methods/common'
import { IData, IMethods, IComputed, SettingList } from '@/types/addy_plus/accounts/settings'

const LIST: SettingList = [
  { key: 'accountDetails', name: 'Account Details' },
  { key: 'administrators', name: 'Manage Users' },
  { key: 'dealerDisclaimer', name: 'Disclaimer' }, // for dealer account only
  { key: 'dealers', name: 'Manage Dealers' }, // for issuer account only
]

export default Vue.extend<IData, IMethods, IComputed>({
  title: 'Account Settings',
  mixins: [pageTitleMixin],
  data() {
    return {
      settingList: [],
    }
  },
  computed: {
    ...mapGetters('addyPlusBase', ['parsedAccounts']),
    accountId() {
      return this.$route.params.accountId
    },
    accountName() {
      return this.parsedAccounts?.[this.accountId]?.name || 'Account'
    },
    isIssuerAccount() {
      return this.parsedAccounts[this.accountId] ? this.parsedAccounts[this.accountId].accountRole === 'issuer' : undefined
    },
  },
  watch: {
    isIssuerAccount: {
      handler(value) {
        if (value !== undefined) this.init()
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      let temp
      if (this.isIssuerAccount) {
        temp = LIST.filter((item) => item.key !== 'dealerDisclaimer')
      } else {
        temp = LIST.filter((item) => item.key !== 'dealers')
      }
      this.settingList = ascSort(temp, 'name')
    },
    onNavigation(key) {
      this.$router.push(`/addyPlus/${this.accountId}/settings/${key}`)
    }
  },
})
